<template>
  <div class="div-main-list-page">
  <div class="div-main-list-page-title" v-if="view === 'MAIN_VIEW'" >
    <h1>Credit/ Debit Note</h1>
  </div>
  <div class="flex-between-row div-main-list-page-tab-search-btn" v-if="view === 'MAIN_VIEW'">
      <el-tabs class="tab-bar" v-model="viewCdTabActive" @tab-click="handleGetCdNotes(cdPagination.pageSize, 0)">
        <el-tab-pane
          v-for="item in CDNOTE_TAB"
          :key="item" :label="capitalizeFirstLetter(item, '_')" :name="item"
        />
      </el-tabs>
    <div class="flex-end" style="align-items: center;">
      <div class="search-bar">
      <el-input
        v-model="cdSearchInvoiceNo" class="round-input"
        placeholder="Search Invoice No. or Company Name" @keypress.enter.native="handleGetCdNotes(cdPagination.pageSize, 0, true)"
      >
        <el-button type="primary" slot="append" icon="el-icon-search" @click="handleGetCdNotes(cdPagination.pageSize, 0, true)"></el-button>
      </el-input>
      </div>
      <div
        style="display: flex; height: 30px;"
        v-if="(view === 'MAIN_VIEW' && viewCdTabActive === CDNOTE_STATUS.PENDING_AUTHORIZED_SIGNATORY_APPROVAL && hasPermission($permissionFunctionCode.AUTHORIZED_SIGNATORY_APPROVAL))"
      >
        <el-button
          round  outline type="success"
          @click="handleApproveRejectCdNote(true)"
        >
        Approve
        </el-button>
        <el-button
          round  type="danger"
          @click="handleApproveRejectCdNote(false)"
        >
          Reject
        </el-button>
      </div>
      <div v-if="(view === 'MAIN_VIEW' && hasPermission($permissionFunctionCode.ADD))">
        <el-dropdown class="primary-button" trigger="click" @command="selectCreateCdNote">
          <span class="el-dropdown-link">
            Create Credit/Debit Note<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="CREDIT_NOTE">Credit Note</el-dropdown-item>
            <el-dropdown-item command="DEBIT_NOTE">Debit Note</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
  <div class="div-main-list-page-table" v-loading="cdNoteTableLoading" v-if="view === 'MAIN_VIEW'">
    <el-table
      :data="cdNoteTableData"
      height="100%"
      :border="true"
      :header-cell-style="HEADER_CELL_STYLE"
      :cell-style="customCellStyle"
      @selection-change="handleCdNoteSelectChange"
    >
      <el-table-column v-if="(view === 'MAIN_VIEW' && viewCdTabActive === CDNOTE_STATUS.PENDING_AUTHORIZED_SIGNATORY_APPROVAL && hasPermission($permissionFunctionCode.AUTHORIZED_SIGNATORY_APPROVAL))"
        key="checkbox" type="selection" min-width="55"
      />
      <el-table-column label="No." prop="systemGeneratedNoteNo" min-width="150px"/>
      <el-table-column label="Company" show-overflow-tooltip min-width="150px">
        <template v-slot="scope">
          {{ isSellerView ? scope.row.buyerCompanyName : scope.row.sellerCompanyName }}
        </template>
      </el-table-column>
      <el-table-column label="Tagged To" min-width="150px">
        <template v-slot="scope">
          <div>{{ scope.row.createdByInvoice.systemGeneratedInvoiceNo }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Description" prop="description" show-overflow-tooltip min-width="200px"/>
      <el-table-column label="Reason" prop="reason" show-overflow-tooltip min-width="150px"/>
      <el-table-column label="Note Total" show-overflow-tooltip min-width="150px">
        <template v-slot="scope">
          <div>{{ `$${numberWithCommas(scope.row.noteTotal, 2)}`}}</div>
        </template>
      </el-table-column>
      <el-table-column width="50px" fixed="right">
        <template v-slot="scope">
          <div class="div-invoice-table-icon" @click="handleRightIconClick(scope.row)" style="cursor: pointer;">
            <i class="el-icon-view" size="large"/>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="div-main-list-page-table-pagination" v-if="!cdNoteTableLoading">
      <el-pagination
        background
        @size-change="handleCdPageSizeChange"
        @current-change="handleCdPageChange"
        :page-size.sync="cdPagination.pageSize"
        :page-sizes="[5, 10, 20, 50, 100]"
        :current-page="cdPagination.pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="cdPagination.total">
      </el-pagination>
    </div>
  </div>
    <cd-note-form v-if="view !== 'MAIN_VIEW'" :propsView="view" :cdNoteId="view === 'CREATE_CD_NOTE' ? null : cdNoteSelected.id"
      :passedNoteType ="noteType" :isSellerView='isSellerView' @handleCloseCdNoteForm="handleCloseCdNoteForm"
    />
  </div>
</template>
<script>
import { CDNOTE_STATUS, CDNOTE_TAB, HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
import CdNoteForm from './components/CdNoteForm'
import { numberWithCommas, capitalizeFirstLetter } from '@/utils/index.js'
import { getCdNotes, approveRejectCdNote } from '@/services/modules/cdNote'

export default {
  name: 'CreditDebitNote',
  components: { CdNoteForm },
  props: {
    data: Object
  },
  data () {
    const cdPagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    /* customizing cell style because min-height for el-table is not working */
    const customCellStyle = () => {
      CELL_STYLE.paddingTop = '18px'
      CELL_STYLE.paddingBottom = '18px'
      return CELL_STYLE
    }
    return {
      CDNOTE_TAB,
      CDNOTE_STATUS,
      HEADER_CELL_STYLE,
      CELL_STYLE,
      view: 'MAIN_VIEW',
      isSellerView: true,
      viewCdTabActive: null,
      cdSearchInvoiceNo: null,
      cdNoteTableLoading: false,
      cdNoteTableData: [],
      cdPagination,
      cdNoteSelected: null,
      noteType: null,
      customCellStyle
    }
  },
  created () {
    this.getInitialView()
  },
  watch: {
    $route () {
      this.cdNoteTableLoading = false
      this.getInitialView()
    }
  },
  methods: {
    numberWithCommas,
    capitalizeFirstLetter,
    getInitialView () {
      this.view = 'MAIN_VIEW'
      this.viewCdTabActive = this.CDNOTE_TAB.APPROVED
      this.$router.currentRoute.name === 'MENU_ACCOUNTS_RECEIVABLE_NOTE' ? this.isSellerView = true : this.isSellerView = false
      this.handleGetCdNotes(50, 0)
    },
    selectCreateCdNote (cdNoteType) {
      this.view = 'CREATE_CD_NOTE'
      this.noteType = cdNoteType
    },
    handleCdPageChange (pageNumber) {
      this.handleGetCdNotes(this.cdPagination.pageSize, pageNumber - 1)
    },
    handleCdPageSizeChange (pageSize) {
      this.handleGetCdNotes(pageSize, 0)
    },
    handleCdNoteSelectChange (selection) {
      this.cdNoteSelected = selection
    },
    handleRightIconClick (row) {
      this.cdNoteSelected = row
      this.view = 'EDIT_CD_NOTE'
    },
    getCdNoteFilterStatus () {
      switch (this.viewCdTabActive) {
        case CDNOTE_TAB.PENDING_CREDIT_AUTHORIZATION:
        case CDNOTE_TAB.PENDING_AUTHORIZED_SIGNATORY_APPROVAL:
        case CDNOTE_TAB.APPLIED:
          return `status:'${this.viewCdTabActive}'`
        case CDNOTE_TAB.APPROVED:
          return `status:'${this.CDNOTE_STATUS.AUTHORIZED_SIGNATORY_APPROVED}'`
        case CDNOTE_TAB.REJECTED:
          return `status in ('${this.CDNOTE_STATUS.CREDIT_AUTHORIZATION_REJECTED}','${this.CDNOTE_STATUS.AUTHORIZED_SIGNATORY_REJECTED}')`
        default:
          return ''
      }
    },
    handleGetCdNotes (pageSize, pageNumber, isSearch) {
      this.cdNoteTableLoading = true
      let params = `${this.getCdNoteFilterStatus()}&sort=updatedAt DESC&isSellerView=${this.isSellerView}`
      if (isSearch) {
        params = `${this.getCdNoteFilterStatus()} and (createdByInvoice.systemGeneratedInvoiceNo~'*${this.cdSearchInvoiceNo}*' or buyerCompanyName~'*${this.cdSearchInvoiceNo}*')&sort=updatedAt DESC&isSellerView=${this.isSellerView}`
      }
      getCdNotes(pageSize, pageNumber, params)
        .then(res => {
          if (res?.code === 1000) {
            this.cdNoteTableData = res.data.content
            this.cdPagination.total = res.data.totalElements
            this.cdPagination.pageSize = res.data.pageable.pageSize
            this.cdNoteTableLoading = false
          }
          this.cdNoteTableLoading = false
        })
        .catch(() => { this.cdNoteTableLoading = false })
    },
    handleApproveRejectCdNote (isApproved) {
      const data = {
        noteIds: [],
        isApproved: isApproved
      }
      if (!this.cdNoteSelected || this.cdNoteSelected.length === 0) {
        this.$message.error('At least one credit/debit note must be selected.')
      } else {
        this.cdNoteSelected.forEach((cdNoteTableItem) => {
          data.noteIds.push(cdNoteTableItem.id)
        })
        approveRejectCdNote(data).then(res => {
          if (res?.code === 1000) {
            if (isApproved) {
              this.$message.success('Credit/ Debit Note has been approved.')
            } else {
              this.$message.success('Credit/ Debit Note has been rejected.')
            }
            this.cdNoteSelected = null
            isApproved ? this.viewCdTabActive = this.CDNOTE_TAB.APPROVED : this.viewCdTabActive = 'REJECTED'
            this.handleGetCdNotes(50, 0)
          }
        })
      }
    },
    handleCloseCdNoteForm (action) {
      this.view = 'MAIN_VIEW'
      // this.viewCdTabActive = this.CDNOTE_TAB.APPROVED
      this.handleGetCdNotes(50, 0)
    }
  }
}
</script>
<style scoped lang="scss">
  @import "index";
</style>
