const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

const formatDate = (date, separator = '-') => {
    return `${padTo2Digits(date.getDate())}${separator}${padTo2Digits(date.getMonth() + 1)}${separator}${date.getFullYear()}`;
}

const formatDateTime = (date, separator = '-') => {
    return `${padTo2Digits(date.getDate())}${separator}${padTo2Digits(date.getMonth() + 1)}${separator}${padTo2Digits(date.getFullYear())} ${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}:${padTo2Digits(date.getSeconds())}`;
}

const toLocalIsoString = (date) => {
    const tzo = -date.getTimezoneOffset()
    const dif = tzo >= 0 ? '+' : '-'
    const pad =  (num) => {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

export {
    formatDate,
    formatDateTime,
    toLocalIsoString
}