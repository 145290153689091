var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "div-main-list-page" },
    [
      _vm.view === "MAIN_VIEW"
        ? _c("div", { staticClass: "div-main-list-page-title" }, [
            _c("h1", [_vm._v("Credit/ Debit Note")]),
          ])
        : _vm._e(),
      _vm.view === "MAIN_VIEW"
        ? _c(
            "div",
            {
              staticClass: "flex-between-row div-main-list-page-tab-search-btn",
            },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "tab-bar",
                  on: {
                    "tab-click": function ($event) {
                      return _vm.handleGetCdNotes(_vm.cdPagination.pageSize, 0)
                    },
                  },
                  model: {
                    value: _vm.viewCdTabActive,
                    callback: function ($$v) {
                      _vm.viewCdTabActive = $$v
                    },
                    expression: "viewCdTabActive",
                  },
                },
                _vm._l(_vm.CDNOTE_TAB, function (item) {
                  return _c("el-tab-pane", {
                    key: item,
                    attrs: {
                      label: _vm.capitalizeFirstLetter(item, "_"),
                      name: item,
                    },
                  })
                }),
                1
              ),
              _c(
                "div",
                {
                  staticClass: "flex-end",
                  staticStyle: { "align-items": "center" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "search-bar" },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "round-input",
                          attrs: {
                            placeholder: "Search Invoice No. or Company Name",
                          },
                          nativeOn: {
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleGetCdNotes(
                                _vm.cdPagination.pageSize,
                                0,
                                true
                              )
                            },
                          },
                          model: {
                            value: _vm.cdSearchInvoiceNo,
                            callback: function ($$v) {
                              _vm.cdSearchInvoiceNo = $$v
                            },
                            expression: "cdSearchInvoiceNo",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleGetCdNotes(
                                  _vm.cdPagination.pageSize,
                                  0,
                                  true
                                )
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.view === "MAIN_VIEW" &&
                  _vm.viewCdTabActive ===
                    _vm.CDNOTE_STATUS.PENDING_AUTHORIZED_SIGNATORY_APPROVAL &&
                  _vm.hasPermission(
                    _vm.$permissionFunctionCode.AUTHORIZED_SIGNATORY_APPROVAL
                  )
                    ? _c(
                        "div",
                        { staticStyle: { display: "flex", height: "30px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                round: "",
                                outline: "",
                                type: "success",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleApproveRejectCdNote(true)
                                },
                              },
                            },
                            [_vm._v(" Approve ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { round: "", type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleApproveRejectCdNote(false)
                                },
                              },
                            },
                            [_vm._v(" Reject ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.view === "MAIN_VIEW" &&
                  _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-dropdown",
                            {
                              staticClass: "primary-button",
                              attrs: { trigger: "click" },
                              on: { command: _vm.selectCreateCdNote },
                            },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _vm._v(" Create Credit/Debit Note"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "CREDIT_NOTE" } },
                                    [_vm._v("Credit Note")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "DEBIT_NOTE" } },
                                    [_vm._v("Debit Note")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.view === "MAIN_VIEW"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cdNoteTableLoading,
                  expression: "cdNoteTableLoading",
                },
              ],
              staticClass: "div-main-list-page-table",
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.cdNoteTableData,
                    height: "100%",
                    border: true,
                    "header-cell-style": _vm.HEADER_CELL_STYLE,
                    "cell-style": _vm.customCellStyle,
                  },
                  on: { "selection-change": _vm.handleCdNoteSelectChange },
                },
                [
                  _vm.view === "MAIN_VIEW" &&
                  _vm.viewCdTabActive ===
                    _vm.CDNOTE_STATUS.PENDING_AUTHORIZED_SIGNATORY_APPROVAL &&
                  _vm.hasPermission(
                    _vm.$permissionFunctionCode.AUTHORIZED_SIGNATORY_APPROVAL
                  )
                    ? _c("el-table-column", {
                        key: "checkbox",
                        attrs: { type: "selection", "min-width": "55" },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "No.",
                      prop: "systemGeneratedNoteNo",
                      "min-width": "150px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Company",
                      "show-overflow-tooltip": "",
                      "min-width": "150px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isSellerView
                                      ? scope.row.buyerCompanyName
                                      : scope.row.sellerCompanyName
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1395261942
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Tagged To", "min-width": "150px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.createdByInvoice
                                      .systemGeneratedInvoiceNo
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3161307503
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Description",
                      prop: "description",
                      "show-overflow-tooltip": "",
                      "min-width": "200px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Reason",
                      prop: "reason",
                      "show-overflow-tooltip": "",
                      "min-width": "150px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Note Total",
                      "show-overflow-tooltip": "",
                      "min-width": "150px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    `$${_vm.numberWithCommas(
                                      scope.row.noteTotal,
                                      2
                                    )}`
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3483061509
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { width: "50px", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "div-invoice-table-icon",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRightIconClick(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-view",
                                    attrs: { size: "large" },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      591640569
                    ),
                  }),
                ],
                1
              ),
              !_vm.cdNoteTableLoading
                ? _c(
                    "div",
                    { staticClass: "div-main-list-page-table-pagination" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "page-size": _vm.cdPagination.pageSize,
                          "page-sizes": [5, 10, 20, 50, 100],
                          "current-page": _vm.cdPagination.pageNumber,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.cdPagination.total,
                        },
                        on: {
                          "size-change": _vm.handleCdPageSizeChange,
                          "current-change": _vm.handleCdPageChange,
                          "update:pageSize": function ($event) {
                            return _vm.$set(
                              _vm.cdPagination,
                              "pageSize",
                              $event
                            )
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(
                              _vm.cdPagination,
                              "pageSize",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.view !== "MAIN_VIEW"
        ? _c("cd-note-form", {
            attrs: {
              propsView: _vm.view,
              cdNoteId:
                _vm.view === "CREATE_CD_NOTE" ? null : _vm.cdNoteSelected.id,
              passedNoteType: _vm.noteType,
              isSellerView: _vm.isSellerView,
            },
            on: { handleCloseCdNoteForm: _vm.handleCloseCdNoteForm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }